import React from 'react';
import { Button } from 'antd';
import OwlCarousel from 'react-owl-carousel';
import {ArrowUpOutlined  } from '@ant-design/icons'; 
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Latesting1 from '../assets/images/latestimg1.jpg';
import Latesting2 from '../assets/images/latestimg2.jpg';
import Latesting3 from '../assets/images/latestimg3.jpg';
import Latesting4 from '../assets/images/LatestImg4.jpg';

import '../Styles/Home.css';


const LatestNews = () => {
  return (
    <div className='container latest-news'>
      <div className='container latest-news'>
        <h1 className='latest-title' data-aos="fade-right" data-aos-duration="1000">Latest News</h1>
        <div className='sub-title d-flex justify-content-between'>
        <h4 data-aos="fade-up" data-aos-duration="1000">Today's News, Tomorrow's Strategy</h4>
        <h5 className='all-services'>
          <span className="plus-icon">Latest News</span> < ArrowUpOutlined  />
        </h5>
      </div>
      </div>
      <OwlCarousel
        className="owl-theme"
        loop
        margin={10}
        nav
        autoplay // Enable autoplay
        autoplayTimeout={3000} // Set autoplay timeout to 3 seconds
        navText={['<i class="fa fa-chevron-left"></i>', '<i class="fa fa-chevron-right"></i>']}
        responsive={{
          0: { items: 1 },
          600: { items: 2 },
          1000: { items: 3 }
        }}
        data-aos="fade-up-right"  data-aos-duration="1000"
      >
        <div className="item latest-content">
          <img src={Latesting1} alt="Image 1" />
          <h3 className='date'>Sun, September 12, 2021</h3>
          <h2>Raycon Environmental Services</h2>
          <p>Nigeria generates more than 32 million tons of solid waste annually and of this volume, only about 20% is collected and properly disposed of according...:</p>
        </div>
        <div className="item  latest-content">
          <img src={Latesting2} alt="Image 2" />
          <h3 className='date'>Wed, July 25, 2018</h3>
          <h2>Adamawa State Governmet on inspection visit to project site</h2>
          <p>Adamawa State Governmet on inspection visit to project site</p>
        </div>
        <div className="item  latest-content">
          <img src={Latesting3} alt="Image 3" />
          <h3 className='date'>Wed, July 25, 2018</h3>
          <h2>Governor Godswill Akpabio Comissioning of our completed toad project</h2>
          <p>Governor Godswill Akpabio Comissioning of our completed toad project in May 2015</p>
        </div>
        <div className="item  latest-content">
          <img src={Latesting4} alt="Image 3" />
          <h3 className='date'>Admin | Wed, July 25, 2018 </h3>
          <h2>Former President Olusegun Obasanjo Commissioning of Our completed Project in Abia State</h2>
          <p>Former President Olusegun Obasanjo Commissioning of Our completed Project in Abia State in 2016</p>
        </div>
      </OwlCarousel>
    </div>
  );
};

export default LatestNews;
