import React from 'react';
import { Row, Col } from 'antd';
import { PlusOutlined, ArrowUpOutlined  } from '@ant-design/icons'; 
import { Link } from 'react-router-dom'; 
import '../Styles/Home.css'; // Import your CSS file

const ServicesType = () => {
  return (
    <div>
         <div className='sub-title d-flex justify-content-between'>
        <h4 data-aos="fade-up" data-aos-duration="1000">Few Words About Raycon & Company .</h4>
        {/* Wrap the "All Services" text with Link */}
        <Link to="/services" className='all-services'>
          <span className="plus-icon">All Services</span> <ArrowUpOutlined />
        </Link>
      </div>
        <p className='home-content' data-aos="fade-up" data-aos-duration="1000">Since our inception in 1972. as a construction, erection and engineering contracting company that has been offering professional capabilities, resources and services to the Oil Producing, Building & Civil Engineering, Environmental Engineering, Corrosion/Pollution Control and Prevention Industries in Nigeria, We have positioned ourself as the leading choice for individuals, NGO's, cooperate organizations and government agencies for years and counting. This, we have achieved through our collaborative/professional approach to work </p>
      <Row gutter={[16, 16]} className='p-4' data-aos="fade-up-right" data-aos-duration="1000"> {/* gutter defines the spacing between columns and rows */}
        {/* First Column */}
        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
          <div className="service-item">
            <span className="plus-icon">< PlusOutlined /></span>
            <Link to="/services" className="custom-link">
              <div className="service-name"><h6 id='collapse1'>Standard and Dual Carriage Roads</h6></div>
            </Link>
          </div>
          <div className="service-item">
            <span className="plus-icon"><PlusOutlined /></span>
            <Link to="/services"  className="custom-link">
            <div  className="service-name"><h6>Construction of Underground drainages</h6></div>
            </Link>
          </div>
          <div className="service-item">
            <span className="plus-icon"><PlusOutlined /></span>
            <Link to="/services"  className="custom-link">
            <div  className="service-name"><h6>Design and Construction of Oil Drilling Location</h6></div> 
            </Link>
          </div>
        </Col>
        {/* Second Column */}
        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
          <div className="service-item">
            <span className="plus-icon"><PlusOutlined /></span>
            <Link to="/services"  className="custom-link">
            <div  className="service-name"><h6>Construction of Airstrip</h6></div>
            </Link>
          </div>
          <div className="service-item">
            <span className="plus-icon"><PlusOutlined /></span>
            <Link to="/services"  className="custom-link">
            <div  className="service-name"><h6>Building of Tank Farms</h6></div>
            </Link>
          </div>
          <div className="service-item">
            <span className="plus-icon"><PlusOutlined /></span>
            <Link to="/services"  className="custom-link">
            <div  className="service-name"><h6>Pump Houses & Metering Stations </h6></div>
            </Link>
          </div>
        </Col>
        {/* Third Column */}
        <Col xs={24} sm={8} md={8} lg={8} xl={8}>
          <div className="service-item">
            <span className="plus-icon"><PlusOutlined /></span>
            <Link to="/services"  className="custom-link">
            <div  className="service-name"><h6>Construction of Bridges</h6></div>
            </Link>
          </div>
          <div className="service-item">
            <span className="plus-icon"><PlusOutlined /></span>
            <Link to="/services"  className="custom-link">
            <div  className="service-name"><h6>Building of Houses</h6></div>
            </Link>
          </div>
          <div className="service-item">
            <span className="plus-icon"><PlusOutlined /></span>
            <Link to="/services"  className="custom-link">
            <div  className="service-name"><h6>Building of Houses</h6></div>
            </Link>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default ServicesType;
