import React, { useEffect, useState } from 'react';
import PostCard from '../Components/PostCard';
import { Box, Container, Grid } from '@mui/material';
import Navbar from '../Components/Navbar';
import Footer from '../Components/Footer';
import axios from 'axios';
import moment from 'moment';
import Loader from '../Components/Loader';
import { io } from 'socket.io-client';

const socket = io('/', {
    reconnection: true
})

const Blog = () => {

    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [postAddLike, setPostAddLike] = useState([]);
    const [postRemoveLike, setPostRemoveLike] = useState([]);

    //display posts

    const showPosts = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get('http://localhost:3000/api/posts/show');
            console.log('ygfyhfg',data);
            setPosts(data.posts);
            setLoading(false);
        } catch (error) {
            console.log(error.response.data.error);
        }
    }

    useEffect(() => {
        showPosts();
    }, []);

    useEffect(() => {
        socket.on('add-like', (newPosts) => {
            setPostAddLike(newPosts);
            setPostRemoveLike('');
        });
        socket.on('remove-like', (newPosts) => {
            setPostRemoveLike(newPosts);
            setPostAddLike('');
        });
    }, [])

    let uiPosts = postAddLike.length > 0 ? postAddLike : postRemoveLike.length > 0 ? postRemoveLike : posts;
    console.log('jk', uiPosts)
    return (
        <>
            <Box sx={{ bgcolor: "#fafafa", minHeight: "100vh" }}>
                <Navbar />
                <div style={{ textAlign: 'left', marginTop: '20px' }} className='title container' >
                <h2 data-aos="fade-left" data-aos-duration="1000">Blog</h2>
                <div className='sub-title'>
                </div>
            </div>
                
                <Container sx={{ pt: 5, pb: 5, minHeight: "83vh" }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={2} justifyContent="center">
                            {
                                loading ? <Loader /> :
                                    uiPosts.map((post, index) => (
                                        <Grid item xs={12} sm={6} md={6} lg={4} key={index} >
                                            <PostCard
                                                height="250"
                                                width="100%"
                                                id={post._id}
                                                title={post.title}
                                                content={post.content}
                                                image={post.image ? post.image.url : ''}
                                                subheader={moment(post.createdAt).format('MMMM DD, YYYY')}
                                                comments={post.comments.length}
                                                likes={post.likes.length}
                                                likesId={post.likes}
                                                showPosts={showPosts}
                                                style={{ objectFit: 'cover' }}
                                            />
                                        </Grid>
                                    ))
                            }
                        </Grid>
                    </Box>
                </Container>
                <Footer />
            </Box>
        </>
    )
}

export default Blog;
