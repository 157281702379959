import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Navbar from '../Components/Navbar';
import { Row, Col ,Typography} from 'antd'; 
import '../Styles/About.css'; 
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import GoalPrinciple from '../Components/GoalPrinciple';
import Collapse from '../Components/Collapse'
import Statistics from '../Components/Statistics';
import Connect from '../Components/Connect';
import Brandlogo from '../Components/Brandlogo';
import Footer  from '../Components/Footer';
import ArrowTopNavigation from '../Components/ArrowTopNavigation'
import {  ArrowLeftOutlined,ArrowRightOutlined } from '@ant-design/icons'; 
import Service7 from '../assets/images/service7.jpg'
import Service8 from '../assets/images/service8.jpg'
import Service9 from '../assets/images/service9.jpg'
import Pioneers from '../Components/Pioneers';
import ShowCase from '../Components/ShowCase';

const { Title, Paragraph } = Typography;


const About = () => {
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const checkNext = () => {
    const labels = document.querySelectorAll('#slider label');
    const nextIndex = selectedIndex === (labels.length - 1) ? 0 : selectedIndex + 1; 
    setSelectedIndex(nextIndex);
  }

  const checkPrevious = () => {
    const labels = document.querySelectorAll('#slider label');
    const previousIndex = selectedIndex === 0 ? labels.length - 1 : selectedIndex - 1; 
    setSelectedIndex(previousIndex);
  }

  const check = index => setSelectedIndex(index);
    useEffect(() => {
        AOS.init({
          // Global settings
          duration: 1000,
          once: true // Whether animation should happen only once
        });
      }, []);
  return (
    <div>
      <Navbar />
      <div className="about-banner">
        <div className="container banner-text animation-top-sentence">
          <h1 className='w3-animate-right'>About Us</h1>
        </div>
      </div>
      <div className="container sub-title">
        <Row gutter={[16, 16]}> 
          <Col xs={24} sm={12} md={12} lg={12} xl={12}> 
          <div style={{ textAlign: 'left', marginTop: '20px' }} className='title w3-animate-left'>
          <h2>About Us</h2>
        </div>
        <div className='sub-title' data-aos="fade-up" data-aos-duration="1000">
          <h4>Few Words About Raycon & Company .</h4>
        <p className='about-para para-justify' data-aos="fade-up" data-aos-duration="1000">Since our inception in 1972. as a construction, erection and engineering contracting company that has been offering professional capabilities, resources and services to the Oil Producing, Building & Civil Engineering, Environmental Engineering, Corrosion/Pollution Control and Prevention Industries in Nigeria, We have positioned ourself as the leading choice for individuals, NGO's, cooperate organizations and government agencies for years and counting. This, we have achieved through our collaborative/professional approach to work </p>
        </div>
          </Col>
          <Col xs={24} sm={12} md={12} lg={12} xl={12}  data-aos="fade-left" data-aos-duration="1000" className='card-slider'>
          <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4 for-padding">
        <div className="md:w-2/4 md:mb-0 mb-6 flex flex-col text-center items-center">
          <section
            id="slider"
            className="w-16 h-20 inline-flex items-center justify-center mb-5 flex-shrink-0"
          >
            <input
              type="radio"
              name="slider"
              id="s1"
              checked={selectedIndex === 0}
              onClick={() => check(0)}
            />
            <input 
              type="radio" 
              name="slider" 
              id="s2" 
              checked={selectedIndex === 1}
              onClick={() => check(1)}
            />
            <input
              type="radio"
              name="slider"
              id="s3"
              checked={selectedIndex === 2}
              onClick={() => check(2)}
            />
            <label htmlFor="s1" id="slide1">
              <img className="fea" src={Service8} height="100%" width="100%"/>
            </label>
            <label htmlFor="s2" id="slide2">
              <img className="fea" src={Service9} height="100%" width="100%"/>
            </label>
            <label htmlFor="s3" id="slide3">
              <img className="fea" src={Service7} height="100%" width="100%"/>
            </label>
          </section>
        </div>
        <div className="d-flex ">
        <div className="md:w-1/4 py-64 md:mb-0 mb-6 flex flex-col text-center items-center">
          <div className="w-20 h-20 inline-flex items-center justify-center rounded-full text-orange-500 mb-5 flex-shrink-0">
            <button onClick={checkPrevious} className='card-btn'><ArrowLeftOutlined /></button>
          </div>
        </div>
        <div className="md:w-1/4 py-64 md:mb-0 mb-6 flex flex-col text-center items-center">
          <div className="w-20 h-20 inline-flex items-center justify-center rounded-full text-orange-500 mb-5 flex-shrink-0">
            <button onClick={checkNext} className='card-btn'><ArrowRightOutlined /></button>
          </div>
        </div>
        </div>
      </div>
                </Col>
        </Row>
        <GoalPrinciple/>
    {/*ShowCase start */}
        <ShowCase/>
    {/* Why Choose Raycon */}
      {/* <div className='choosen-raycons pt-5'>
       <Collapse/>
      </div> */}
        {/* Statistics Section Start */}
        {/* <Statistics/> */}
      </div>
         {/* Connect Section Start */}
         <Pioneers/>
         {/* Pioneers Section Start */}
         <Connect/>
           {/* Brandlogo Section Start */}
           {/* <Brandlogo/> */}
         {/* Footer Section Start */}
         <Footer/>
          {/* ArrowTopNavigation Section Start */}
          <ArrowTopNavigation/>
  
    </div>
  );
}

export default About;
