import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import '../Styles/Navbar.css';
import Logo from '../assets/images/Logo.png';
import { FacebookOutlined, InstagramOutlined, LinkedinOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState('');

  useEffect(() => {
    setCurrentPage(location.pathname);
  }, [location]);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div>
      <nav className="navbar navbar-fixed">
        <div className="navbar-container container">
          <div className="navbar-logo">
            <div className='logo d-flex'>
              <div><img src={Logo} alt="Logo" /></div>
              <div>
                <FacebookOutlined style={{ fontSize: '20px', marginLeft: '10px',color:'#cfcece' }} />
                <InstagramOutlined style={{ fontSize: '20px', marginLeft: '10px',color:'#cfcece' }} />
                <LinkedinOutlined style={{ fontSize: '20px', marginLeft: '10px',color:'#cfcece' }} />
              </div>
            </div>
          </div>
          <div className={`navbar-links ${isOpen ? "active" : ""}`}>
            <a className={currentPage === '/' ? 'active' : ''}><Link to='/'>Home</Link></a>
            <a className={currentPage === '/about' ? 'active' : ''}><Link to='/about'>About Us</Link></a>
            <a className={currentPage === '/services' ? 'active' : ''}><Link to='/services'>Services</Link></a>
            <a className={currentPage === '/blog' ? 'active' : ''}><Link to='/blog'>Blog</Link></a>
            <a className={currentPage === '/gallery' ? 'active' : ''}><Link to='/gallery'>Gallery</Link></a>
            <a className={currentPage === '/news-media' ? 'active' : ''}><Link to='/news-media'>News/Media</Link></a>
            <a className={currentPage === '/equipments' ? 'active' : ''}><Link to='/equipments'>Equipments</Link></a>
            <a className={currentPage === '/carrer' ? 'active' : ''}><Link to='/carrer'>Career</Link></a>
            <a className={currentPage === '/contact' ? 'active' : ''}><Link to='/contact'>Contact</Link></a>
          </div>
          <div className="navbar-toggle" onClick={toggleMenu}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
