import React from 'react';
import { Row, Col } from 'antd';
import case1 from '../assets/images/service1.jpg';
import case2 from '../assets/images/service2.jpg';
import case3 from '../assets/images/service3.jpg';
import case4 from '../assets/images/service9.jpg';
import case5 from '../assets/images/service8.jpg';
import case6 from '../assets/images/parkerplant.jpg';
import '../Styles/About.css';

const ShowCase = () => {
  return (
    <div>
      <div className='container latest-news'>
        <h1 className='latest-title' data-aos="fade-right" data-aos-duration="1000">Showcase</h1>
        <div className='sub-title d-flex justify-content-between'>
          <h4 data-aos="fade-up" data-aos-duration="1000">Unleashing Potential, Uniting Progress</h4>
        </div>
      </div>

      {/* Row with three columns on large screens and two columns on medium screens */}
      <Row gutter={[16, 16]} justify="center" style={{ marginTop: '20px' }}>
        <Col xs={24} sm={12} md={12} lg={8}>
          {/* Image for first column */}
          <div className="show-image-container p-1">
            <img src={case1} alt="First Image" className="zoom-image" style={{ width: '100%', height: '400px' }} />
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8}>
          {/* Image for second column */}
          <div className="show-image-container p-1">
            <img src={case2} alt="Second Image" className="zoom-image" style={{ width: '100%', height: '400px' }} />
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8}>
          {/* Image for third column */}
          <div className="show-image-container p-1">
            <img src={case3} alt="Third Image" className="zoom-image" style={{ width: '100%', height: '400px' }} />
          </div>
        </Col>
      </Row>
      <Row gutter={[16, 16]} justify="center" style={{ marginTop: '20px' }}>
        <Col xs={24} sm={12} md={12} lg={8}>
          {/* Image for first column */}
          <div className="show-image-container p-1">
            <img src={case4} alt="First Imagse" className="zoom-image" style={{ width: '100%', height: '400px' }} />
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8}>
          {/* Image for second column */}
          <div className="show-image-container p-1">
            <img src={case5} alt="Second Image" className="zoom-image" style={{ width: '100%', height: '400px' }} />
          </div>
        </Col>
        <Col xs={24} sm={12} md={12} lg={8}>
          {/* Image for third column */}
          <div className="show-image-container p-1">
            <img src={case6} alt="Third Image" className="zoom-image" style={{ width: '100%', height: '400px' }} />
          </div>
        </Col>
      </Row>
    </div>
  )
}

export default ShowCase;
