import React, { useEffect, useState } from 'react';
import { Row, Col, Form, Input, Button } from 'antd';
import { EnvironmentOutlined, MailOutlined, PhoneOutlined, FacebookOutlined, InstagramOutlined, LinkedinOutlined } from '@ant-design/icons';
import axios from 'axios';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '../Styles/Contact.css';

const ContactForm = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true
        });
    }, []);

    const [form] = Form.useForm();
    const [submitted, setSubmitted] = useState(false);

    const onFinish = async (values) => {
        console.log('test values',values);
        try {
            const response = await axios.post('http://localhost:5000/api/contact', values);
            console.log(response.data);
            setSubmitted(true);
            form.resetFields();
        } catch (error) {
            console.error('Error submitting form:', error);
        }
    };
  return (
    <div>
        <div className="container mt-4">
                <Row gutter={[16, 16]}>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12}>
                        <div style={{ textAlign: 'left', marginTop: '20px' }} className='title w3-animate-left'>
                            <h2>Let's Talk</h2>
                        </div>
                        <div className='sub-title' data-aos="fade-up" data-aos-duration="1000">
                            <h4>Got a project on your mind? Let's discuss about the details.</h4>
                        </div>
                        <p className='contact-para' data-aos="fade-up" data-aos-duration="1000">We try our best to respond to all enquiries within the shortest possible time. Be rest assured that we would respond to your message within the shortest possible time. Thank you</p>
                        <div className="contact-number" data-aos="fade-up" data-aos-duration="1000">
                            <PhoneOutlined style={{ fontSize: '24px', color: '#949db4', marginRight: '10px' }} />
                            <span className='contact-numbers'>cell : +1234567890</span>
                        </div>
                        <div className="contact-info">
                            <div className="contact-item" data-aos="fade-up" data-aos-duration="1000">
                                <EnvironmentOutlined style={{ fontSize: '24px', marginRight: '10px' }} />
                                <span>Main Office</span>
                                <p>Ogunu Road P.O. Box 568, Warri Delta State, Nigeria.</p>
                            </div>
                            <div className="contact-item" data-aos="fade-up" data-aos-duration="1000">
                                <EnvironmentOutlined style={{ fontSize: '24px', marginRight: '10px' }} />
                                <span>Uyo</span>
                                <p>Km8 Calabar - Itu Road Uyo, AkwaIbom State, Nigeria.</p>
                            </div>
                            <div className="contact-item" data-aos="fade-up" data-aos-duration="1000">
                                <EnvironmentOutlined style={{ fontSize: '24px', marginRight: '10px' }} />
                                <span>Abuja</span>
                                <p>Cadastral Zone C16,Idu Industrial Area, Abuja</p>
                            </div>
                            <div className="contact-item" data-aos="fade-up" data-aos-duration="1000">
                                <MailOutlined style={{ fontSize: '24px', marginRight: '10px' }} />
                                <span>Email Address</span>
                                <p>enquiries@raycon.net</p>
                                <div className="contact-social-icons">
                                    <a href="https://www.facebook.com/example"><FacebookOutlined style={{ fontSize: '24px', color: '#949db4', marginRight: '10px' }} /></a>
                                    <a href="https://www.instagram.com/example"><InstagramOutlined style={{ fontSize: '24px', color: '#949db4', marginRight: '10px' }} /></a>
                                    <a href="https://www.linkedin.com/example"><LinkedinOutlined style={{ fontSize: '24px', color: '#949db4', marginRight: '10px' }} /></a>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} className='pb-5'>
                        <div className="contact-form" data-aos="fade-left" data-aos-duration="1000">
                            <h2 className='p-5'>Contact Form</h2>
                            <Form form={form} onFinish={onFinish} layout="vertical">
                                <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please enter your name' }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Please enter your email' }, { type: 'email', message: 'Please enter a valid email' }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="subject" label="Subject" rules={[{ required: true, message: 'Please enter the subject' }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="phone" label="Phone Number" rules={[{ required: true, message: 'Please enter your phone number' }]}>
                                    <Input />
                                </Form.Item>
                                <Form.Item name="message" label="Message" rules={[{ required: true, message: 'Please enter your message' }]}>
                                    <Input.TextArea />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Send
                                    </Button>
                                    <Button type="default" htmlType="button" onClick={() => form.resetFields()}>
                                        Reset
                                    </Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </div>
    </div>
  )
}

export default ContactForm
