import React from 'react';
import { Layout } from 'antd';
import { Row, Col } from 'antd';
import { MailOutlined, PhoneOutlined, HomeOutlined, UserOutlined, ToolOutlined, SafetyCertificateOutlined, FieldTimeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom'; // Import Link from React Router
import Footerlogo from '../assets/images/footerLogo.png';
import '../Styles/Footer.css';

const { Footer } = Layout;

const CustomFooter = () => {
  
  return (
    <Footer className="custom-footer">
      <div className='container'>
      <Row gutter={[16, 16]} justify="center">
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <div className="footer-column">
            <img src={Footerlogo} alt="logo" className="footer-logo" />
            <div className="footer-address">
              <p>Main Office</p>
              <p>73 Ogunu Road P.O. Box 568, Warri Delta State, Nigeria.</p>
              <p>Uyo</p>
              <p>Km8 Calabar - Itu Road Uyo, AkwaIbom State, Nigeria.</p>
              <p>Abuja</p>
              <p>Cadastral Zone C16,Idu Industrial Area, Abuja</p>
              <p><PhoneOutlined /> +234-803-5487672</p>
              <p><MailOutlined /> enquiries@raycon.net</p>
              <p>Click Here : <a href="http://www.raycon.net" target="_blank" rel="noopener noreferrer">www.raycon.net</a></p>
            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <div className="footer-column">
            <div className="useful-links opening-hours">
              <h3>Useful Links</h3>
              <ul>
                <li> <Link to="/" className="custom-links" style={{ color: 'white' }}><h6 style={{ color: 'white' }}>Home</h6></Link></li>
                <li> <Link to="/about" className="custom-links" style={{ color: 'white' }}><h6 style={{ color: 'white' }}>About Us</h6></Link></li>
                <li> <Link to="/services" className="custom-links" style={{ color: 'white' }}><h6 style={{ color: 'white' }}>Services</h6></Link></li>
                <li> <Link to="/blog" className="custom-links" style={{ color: 'white' }}><h6 style={{ color: 'white' }}>Blog</h6></Link></li>
                <li> <Link to="/gallery" className="custom-links" style={{ color: 'white' }}><h6 style={{ color: 'white' }}>Gallery</h6></Link></li>
                <li> <Link to="/equipments" className="custom-links" style={{ color: 'white' }}><h6 style={{ color: 'white' }}>Equipment</h6></Link></li>
                <li> <Link to="/carrer" className="custom-links" style={{ color: 'white' }}><h6 style={{ color: 'white' }}>Career</h6></Link></li>
                <li> <Link to="/contact" className="custom-links" style={{ color: 'white' }}><h6 style={{ color: 'white' }}>Contact Us</h6></Link></li>
              </ul>

            </div>
          </div>
        </Col>
        <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
          <div className="footer-column">
            <div className="opening-hours">
              <h3>Opening Hours</h3>
              <p>Mon - Tues: 8.00 am - 5.00 pm</p>
              <p>Wednes - Thurs: 8.00 am - 5.00 pm</p>
              <p>Fri: 8.00 am - 4.00 pm</p>
              <p>Sat & Sun: Closed But Onsite</p>
            </div>
          </div>
        </Col>
      </Row>
      </div>
      <div className="copyright pt-4">
        &copy; 2024 - Raycon - Powered by <a href='https://techslideitsolutions.com/'>TechSlide ITS</a>
      </div>
    </Footer>
  );
}

export default CustomFooter;
