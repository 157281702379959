import React, { useEffect, useState } from 'react';
import Navbar from '../Components/Navbar';
import { Card, Button } from 'antd';
import '../Styles/Gallery.css';
import GalleryImages from '../assets/images/construction.jpg';
import Footer from '../Components/Footer';
import ArrowTopNavigation from '../Components/ArrowTopNavigation'
import PostCard from '../Components/PostCard';
import { Box, Container, Grid } from '@mui/material';
import axios from 'axios';
import CardMedia from '@mui/material/CardMedia';
import moment from 'moment';
import Loader from '../Components/Loader';
import { io } from 'socket.io-client';

const socket = io('/', {
    reconnection: true
})

const { Meta } = Card;
const Gallery = () => {
    const [posts, setPosts] = useState([]);
    const [loading, setLoading] = useState(false);
    const [postAddLike, setPostAddLike] = useState([]);
    const [postRemoveLike, setPostRemoveLike] = useState([]);

    //display posts

    const showPosts = async () => {
        setLoading(true);
        try {
            const { data } = await axios.get('http://localhost:3000/api/images/show');
            setPosts(data.images);
            setLoading(false);
        } catch (error) {
            console.log(error.response.data.error);
        }
    }

    useEffect(() => {
        showPosts();
    }, []);

    useEffect(() => {
        socket.on('add-like', (newPosts) => {
            setPostAddLike(newPosts);
            setPostRemoveLike('');
        });
        socket.on('remove-like', (newPosts) => {
            setPostRemoveLike(newPosts);
            setPostAddLike('');
        });
    }, [])

    let uiPosts = posts.length > 0 ? postAddLike.length > 0 ? postAddLike : postRemoveLike.length > 0 ? postRemoveLike : posts : [];
    console.log('jk', uiPosts)
    return (
        <div>
            <Navbar />
            <div className="gallery-banner">
                <div className="container gallery-text animation-top-sentence">
                    <h1 className='w3-animate-right'>Gallery</h1>
                </div>
            </div>
            <div style={{ textAlign: 'left', marginTop: '20px' }} className='title container' >
                <h2 data-aos="fade-left" data-aos-duration="1000">Work Gallery</h2>
                <div className='sub-title'>
                    <h4 data-aos="fade-up" data-aos-duration="1000">Past/Current projects</h4>
                    <p data-aos="fade-up" data-aos-duration="1000">Find a list of work/project gallery of completed/ongoing projects. Our years of experience in construction is our edge and advantage in the industry </p>
                </div>
            </div>
            <Box sx={{ bgcolor: "#fafafa", minHeight: "100vh" }}>
                <Container sx={{ pt: 5, pb: 5, minHeight: "83vh" }}>
                    <Box sx={{ flexGrow: 1 }}>
                        <Grid container spacing={{ xs: 2, md: 3 }} justifyContent="center">
                            {
                                loading ? <Loader /> :
                                    uiPosts.map((post, index) => (
                                        <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                                            <CardMedia
                                                component="img"
                                                height="250"
                                                width="100%"
                                                image={post.image ? post.image.url : ''}
                                                alt="Post Image"
                                                style={{ objectFit: 'cover' }}
                                            />
                                        </Grid>
                                    ))
                            }
                        </Grid>
                    </Box>
                </Container>
            </Box>
            {/* Footer Section Start */}
            <Footer />
            {/* ArrowTopNavigation Section Start */}
            <ArrowTopNavigation />
        </div>
    )
}

export default Gallery;
