import React from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import { Link } from 'react-router-dom';

const PostCard = (props) => {
    return (
        <Card sx={{ maxWidth: 345 }}>
            <CardHeader
                avatar={
                    <Avatar aria-label="recipe">
                        R
                    </Avatar>
                }
                title={
                    <Typography variant="h6" component="div" fontWeight="bold">
                        {props.title}
                    </Typography>
                }
            />
            <Link to="/post/:id">
                <CardMedia
                    component="img"
                    height="194"
                    image={props.image}
                    alt="Post Image"
                />
            </Link>
            <CardContent>
                <Typography variant="body2" color="text.secondary">
                    <div dangerouslySetInnerHTML={{ __html: props.content }} />
                </Typography>
            </CardContent>
        </Card>
    );
}

export default PostCard;
