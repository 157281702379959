import React from 'react';
import { Row, Col } from 'antd';
import { ArrowUpOutlined } from '@ant-design/icons';
import Man1 from '../assets/images/man1.jpg';
import Man2 from '../assets/images/man2.jpg';

import '../Styles/RecentWork.css';

const Pioneers = () => {
  return (
    <div>
      <div className='container latest-news'>
        <h1 className='latest-title' data-aos="fade-right" data-aos-duration="1000">Meet the Industry<br/>Pioneers</h1>
        <div className='sub-title d-flex justify-content-between'>
          <h4 data-aos="fade-up" data-aos-duration="1000">Stronger Together, Innovating Forever</h4>
        </div>
        <Row gutter={[16, 16]} justify="space-around">
          <Col xs={24} sm={8} className='p-4' data-aos="fade-right" data-aos-duration="3000">
            <div className="image-container">
              <img src={Man1} alt="Image 1" className="pioneer-image" />
              <div className="overlay">
                <div className="text">
                  <h3>John Doe</h3>
                  <p>CEO, ABC Company</p>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={8} className='p-4' data-aos="fade-up" data-aos-duration="3000">
            <div className="image-container"  style={{ marginTop: '-25px' }}>
              <img src={Man2} alt="Image 2" className="pioneer-image" />
              <div className="overlay">
                <div className="text">
                  <h3>Jane Smith</h3>
                  <p>CTO, XYZ Inc.</p>
                </div>
              </div>
            </div>
          </Col>
          <Col xs={24} sm={8} className='p-4' data-aos="fade-left" data-aos-duration="3000">
            <div className="image-container">
              <img src={Man1} alt="Image 3" className="pioneer-image" />
              <div className="overlay">
                <div className="text">
                  <h3>Mike Johnson</h3>
                  <p>Founder, DEF Corporation</p>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Pioneers;
