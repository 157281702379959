import React from 'react';
import { Row, Col } from 'antd';
import Earth from '../assets/images/earth.png'
import Triangle from '../assets/images/triangle.png'
import Quality from '../assets/images/quality.png'
import Guarenty from '../assets/images/guarenty.png'


const Assurance = () => {
  return (
    <div className="assurance-container"  >
      <Row gutter={[16, 16]} justify="center">
        <Col xs={24} sm={12} md={6} lg={6} data-aos="fade-up" data-aos-duration="1000">
          <div className="assurance-columns">
            <img src={Earth} alt="Image 1" />
            <h3>Product Design and Planning</h3>
            <p>The process begins with product design and planning, where the company's engineers</p>
          </div>
        </Col>
        <Col xs={24} sm={12} md={6} lg={6} data-aos="fade-up" data-aos-duration="2000">
          <div className="assurance-columns">
            <img src={Triangle} alt="Image 2" />
            <h3>Component Sourcing and Procurement </h3>
            <p>Once the design is finalized, the company identifies the required electronic components.</p>
          </div>
        </Col>
        <Col xs={24} sm={12} md={6} lg={6} data-aos="fade-up" data-aos-duration="3000">
          <div className="assurance-columns">
            <img src={Quality} alt="Image 3" />
            <h3>Testing and Quality Control</h3>
            <p>Once the design is finalized, the company identifies the required electronic components</p>
          </div>
        </Col>
        <Col xs={24} sm={12} md={6} lg={6} data-aos="fade-up" data-aos-duration="4000">
          <div className="assurance-columns">
            <img src={Guarenty} alt="Image 4" />
            <h3>Final Assembly and Integration </h3>
            <p>Lorem ipsum is placeholder text commonly used in the graphic, print</p>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Assurance;
