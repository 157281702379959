import React, { useState, useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Row, Col, Form, Input, Button, Typography,Collapse } from 'antd';
import Navbar from '../Components/Navbar';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import '../Styles/Carrer.css';
import Footer from '../Components/Footer';
import ArrowTopNavigation from '../Components/ArrowTopNavigation';
import Testimonials1 from '../assets/images/testinomial1.png';
import Testinomial2 from '../assets/images/Testinomial2.png';
import ParkerPlant from '../assets/images/parkerplant.jpg';

const { Title, Paragraph } = Typography;

const Carrer = () => {
    const { Panel } = Collapse;
    useEffect(() => {
        AOS.init({
            // Global settings
            duration: 1000,
            once: true
        });
    }, []);

    const [form] = Form.useForm();
    const [submitted, setSubmitted] = useState(false);

    const onFinish = (values) => {
        console.log('Received values of form: ', values);
        // Here you can handle form submission, like sending data to a server
        setSubmitted(true);
        // Optionally, reset form fields
        form.resetFields();
    };

    // Owl Carousel options
    const owlOptions = {
        items: 1,
        loop: true,
        autoplay: true,
        autoplayTimeout: 5000,
        autoplayHoverPause: true,
        nav: true,
        dots: true,
        navText: ['<', '>'],
        responsive: {
            0: {
                items: 1
            },
            768: {
                items: 1
            },
            992: {
                items: 1
            }
        }
    };

    return (
        <div>
            {/* Navbar Start */}
            <Navbar />
            <div className="carrer-banner">
                <div className="container carrer-text animation-top-sentence">
                    <h1 className='w3-animate-right'>Carrer</h1>
                </div>
            </div>
               {/* Contact Form Section */}
               <div className='container'> 
                <Row gutter={[16, 16]} className='pt-5'>
                    <Col xs={24} sm={12} md={12} lg={12} xl={12} className='pt-5'>
                    <Collapse defaultActiveKey={['1']} className="mt-4"  data-aos="fade-right" data-aos-duration="1000">
    <Panel header="Available Jobs" key="1" className='available-job custom-panel'>
        <h3>Job :1</h3>
        <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups</p>
        <h3>Job :2</h3>
        <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups</p>
        <h3>Job :3</h3>
        <p>Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups</p>
        <h3>Job :4</h3>
        <p>Job 1:Lorem ipsum is placeholder text commonly used in the graphic, print, and publishing industries for previewing layouts and visual mockups</p>
        {/* Add more job vacancy information as needed */}
    </Panel>
</Collapse>
                    </Col>
                    <Col xs={24} sm={24} md={12} lg={12} xl={12} className='pb-5'  data-aos="fade-left" data-aos-duration="1000">
                    <div className="contact-form-container">
                    <Form form={form} onFinish={onFinish} layout="vertical">
                    <Title level={2} className='parkerTitle pb-4'>Join With Us</Title>
                        <Form.Item name="name" label="Name" rules={[{ required: true, message: 'Please enter your name' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="email" label="Email" rules={[{ required: true, message: 'Please enter your email' }, { type: 'email', message: 'Please enter a valid email' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="phone" label="Phone Number" rules={[{ required: true, message: 'Please enter your phone number' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="address" label="Address" rules={[{ required: true, message: 'Please enter your address' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="resume" label="Upload Resume">
                            <Input type="file" />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">Submit</Button>
                        </Form.Item>
                    </Form>
            </div>
             
                    </Col>
                </Row>
                </div>
            {/* Row with two columns */}
            <Row justify="center" style={{ marginTop: '20px' }} className='container'>
                <Col xs={24} sm={12} md={12} lg={12} xl={12} className='testimonals-content p-5'  data-aos="fade-right" data-aos-duration="1000">
                    <h3 className='pb-5'>Client's Testimonials</h3>
                    <OwlCarousel className="owl-theme" {...owlOptions}>
                        <div className="item testimonials-content">
                            <img src={Testimonials1} alt="Testimonial 1" />
                            <Title level={4}>Shell Nigeria - Nigeria</Title>
                            <Paragraph>
                                Perfect company to work very consistent in project delivery
                            </Paragraph>
                        </div>
                        <div className="item testimonials-content">
                            <img src={Testinomial2} alt="Testimonial 2" />
                            <Title level={4}>Shell Nigeria - Nigeria</Title>
                            <Paragraph>
                                Perfect company to work very consistent in project delivery
                            </Paragraph>
                        </div>
                        {/* Add more items as needed */}
                    </OwlCarousel>
                </Col>
                <Col xs={24} sm={12} md={12} lg={12} xl={12}  data-aos="fade-left" data-aos-duration="1000">
                    {/* Content for second column */}
                    <Row justify="center">
                        <Col>
                            <img src={ParkerPlant} alt="Parker Plant" style={{ maxWidth: '100%', height: 'auto' }} />
                        </Col>
                    </Row>
                    <Row justify="left" className='parkerTitle'>
                        <Col>
                            <Title level={4} className='parkerTitle'>About ParkerPlant</Title>
                        </Col>
                    </Row>
                    <Row justify="left">
                        <Col>
                            <Paragraph className='para-justify '>
                            Parker Plant is a market leader in the manufacture and supply of reliable solutions for asphalt, crushing, screening, sand and gravel, concrete and material handling equipment throughout the World.From its humble beginnings beneath a railway arch in Leicester, the business that Frederick Parker established in 1911 went on to achieve success around the world, with products sold to over 150 countries.
                            Parker Plant today continues Frederick Parker’s proud tradition of supplying high quality, reliable products to meet customers’ requirements. The Parker group now comprises the established and well respected brands of Universal Conveyors, Cartem and Phoenix Transworld, each bringing a wealth of experience and technical expertise in their respective fields.
                            </Paragraph>
                        </Col>
                    </Row>
                </Col>
            </Row>
            {/* Footer Section Start */}
            <Footer />
            {/* ArrowTopNavigation Section Start */}
            <ArrowTopNavigation />
        </div>
    );
}

export default Carrer;
